<template>
  <div>
    <BaseModal
      :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :classesAdicionaisBackDrop="'align-items-start'"
      :classesAdicionaisHeader="'d-none'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalPesquisaCliente"
    >
      <div slot="body">
        <CrmSelecao
          :buscarPorCodigo="true"
          :tipoCrmPesquisa="'F'"
          :permiteCadastro="false"
          ref="SelecaoClienteInput"
          v-model="Selecionado"
          @close="fechar"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
  import CrmSelecao from '@/components/shared/crm/CrmSelecao.vue';

  export default {
    name: 'FornecedorSelecao',

    components: {
      CrmSelecao,
    },

    data() {
      return {
        Selecionado: null,
        UI: {
          CadastroAberto: false,
        },
      };
    },

    methods: {
      fechar() {
        this.$emit('fechar');
      },

      selecionar(fornecedor) {
        this.$emit('selecionar', fornecedor);
      },
    },

    watch: {
      Selecionado: function (fornecedor) {
        this.selecionar(fornecedor);
        this.fechar();
      },
    },
  };
</script>
