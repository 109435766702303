import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';

const CrmService = {
  listar(payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlCrmApi() + '/api/CrmAutocompletar/Pesquisar?' + payload;

      apiClient
        .get(urlRequest)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default CrmService;
